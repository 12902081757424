// import { ThemeProvider } from '@memoresa/ui-components';
import { ThemesMapper } from '@memoresa/themes';
import { getCookie } from 'cookies-next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import qs from 'query-string';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookieDetector } from '../components/cookies/CookieDetector';
import DialogConsumer from '../components/dialogs/DialogConsumer';
import AppLayout from '../components/layout/AppLayout';
import { MetaContent } from '../components/meta/MetaContent';
import { InternetConnectionChecker } from '../components/noInternetConnection/NoInternetConnection';
import { AlertProvider } from '../context/alert/AlertProvider';
import { CartContextProvider } from '../context/cart/useCartContext';
import { DialogProvider } from '../context/dialogs/DialogsContext';
import { ThemeProvider } from '../context/theme/ThemeProvider';
import '../i18n';
import * as fbq from '../lib/fpixel';
import * as ga from '../lib/ganalytics';
import '../resources/styles/globals.css';
const MyApp: any = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const consent = getCookie('cookiePreferences');
  const metaSlug =
    router.asPath === '/shop/faq' ? 'faq' : (router.query.slug as string);

  //Save queries in the session storage
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const source =
        router.query?.m_src ?? qs.parse(qs.extract(router.asPath))?.m_src;
      const campaign =
        router.query?.m_campaign ??
        qs.parse(qs.extract(router.asPath))?.m_campaign;
      const ref = router.query?.ref ?? qs.parse(qs.extract(router.asPath))?.ref;
      const session = window.sessionStorage;
      if (
        source &&
        (!session.getItem('source') ||
          session.getItem('source') === 'undefined')
      ) {
        session.setItem('source', source as string);
      }
      if (
        campaign &&
        (!session.getItem('campaign') ||
          session.getItem('campaign') === 'undefined')
      ) {
        session.setItem('campaign', campaign as string);
      }
      if (
        ref &&
        (!session.getItem('campaign') ||
          session.getItem('campaign') === 'undefined')
      ) {
        session.setItem('campaign', ref as string);
      }
    }
  }, []); //eslint-disable-line

  // Facebook and Google cookie traking
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    consent === 'all' && fbq.pageview();

    const handleRouteChange = url => {
      if (consent === 'all') {
        ga.pageview(url);
        fbq.pageview();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, consent]);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <>
      {consent === 'all' && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
          }}
        />
      )}
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script
        id="ga_init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });

            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {consent === 'all' && (
        <Script
          strategy="afterInteractive"
          id="consupd"
          dangerouslySetInnerHTML={{
            __html: `
            gtag('consent', 'update', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted'
            });
          `,
          }}
        />
      )}
      {/*HotJar integration */}
      <Script
        id="hotjar"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
      {/* TODO: In the future, we should have titles, images and descriptions for each campaign product */}
      <MetaContent
        title={metaSlug && t(`meta.${metaSlug.replace('-a1', '')}.title`)}
        description={
          metaSlug && t(`meta.${metaSlug.replace('-a1', '')}.description`)
        }
        url={metaSlug && (metaSlug as string)}
        image={metaSlug && t(`meta.${metaSlug.replace('-a1', '')}.image`)}
      />
      {typeof window !== 'undefined' && (
        <QueryClientProvider client={queryClient}>
          <ThemeProvider themeObject={ThemesMapper['mem']}>
            <AlertProvider>
              <CartContextProvider>
                <DialogProvider>
                  <CookieDetector />
                  <AppLayout>
                    <InternetConnectionChecker>
                      <Component {...pageProps} />
                    </InternetConnectionChecker>
                  </AppLayout>
                  <DialogConsumer />
                </DialogProvider>
              </CartContextProvider>
            </AlertProvider>
          </ThemeProvider>
        </QueryClientProvider>
      )}
    </>
  );
};

export default MyApp;
