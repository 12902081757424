import {
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiMenu,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@memoresa/ui-components';
import { useRouter } from 'next/router';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import i18n from '../../i18n';
import * as ga from '../../lib/ganalytics';
import CategoriesService from '../../services/categories/CategoriesService';
import { BASE_URLS } from '../../utils/consts/baseProductUrls';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { CartButton } from '../buttons/CartButton';
import { MenuPopover } from './MenuPopover';
export const Navbar = () => {
  const [isMenuPopoverOpen, setIsMenuPopoverOpen] = useState(false);
  const { isSm } = useWindowSize();
  const router = useRouter();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [pageIsScrolled, setPageIsScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', function (e) {
      setPageIsScrolled(window.pageYOffset > 0);
    });
    return () => window.removeEventListener('scroll', () => null);
  }, []);
  const sessionCampaign =
    typeof window !== 'undefined'
      ? (window.sessionStorage.getItem('campaign') as string)
      : undefined;
  const { data: navigationEntries } = useQuery(
    ['navigationEntries', router.locale, sessionCampaign],
    () =>
      CategoriesService.getNavigationEntries({
        campaign: sessionCampaign,
      }),
    {}
  );
  const query = qs.extract(router.asPath)?.length
    ? qs.extract(router.asPath)
    : qs.stringify({
        m_campaign:
          window.sessionStorage.getItem('campaign') !== null
            ? window.sessionStorage.getItem('campaign')
            : undefined,
        m_src:
          window.sessionStorage.getItem('source') !== null
            ? window.sessionStorage.getItem('source')
            : undefined,
      });

  return (
    <div className="fixed top-0 left-0 w-full z-[101]">
      <div className="relative flex flex-col w-full bg-white border-b border-b-primary-10">
        {!pageIsScrolled && (
          <div className="justify-center w-full bg-[#F7F7F7] md:flex hidden ">
            <div className="flex justify-between w-full font-medium text-xs min-h-[24px] text-[#0000008A] px-4 space-x-2 md:px-0 md:w-3/4 lg:w-2/3 max-w-[1280px]">
              <div className="flex items-center space-x-1">
                <Icon path={mdiCheck} size={0.8} />
                <Typography className="uppercase">
                  {t('navigation.topBar.experience')}
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <Icon path={mdiCheck} size={0.8} />
                <Typography className="uppercase">
                  {t('navigation.topBar.legal')}
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <Icon path={mdiCheck} size={0.8} />
                {/* TODO add BE call to get min amount for free shipping */}
                <Typography className="uppercase">
                  {t('navigation.topBar.freeShipping', { value: 20 })}
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <Icon path={mdiCheck} size={0.8} />
                <Typography className="uppercase">
                  {t('navigation.topBar.delivery')}
                </Typography>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-between md:justify-center items-center w-full h-[54px] md:px-0 px-2 md:h-[97px] ">
          <div className="block md:hidden ">
            <div
              onClick={() => setIsMenuPopoverOpen(isOpen => !isOpen)}
              className="w-[36px] aspect-square rounded-full flex items-center justify-center cursor-pointer hover:bg-primary-5 group active:bg-primary focus-visible:border-2 focus-visible:border-primary outline-none"
            >
              <Icon
                path={isMenuPopoverOpen ? mdiClose : mdiMenu}
                size={1}
                className="group-active:text-primary-contrast group-hover:text-primary-40 text-primary"
              />
            </div>
            <MenuPopover
              isOpen={isMenuPopoverOpen}
              setIsOpen={setIsMenuPopoverOpen}
              categories={navigationEntries}
            />
          </div>
          <div className="space-x-2 w-max h-[45px] bg-primary-contrast flex items-center justify-between relative  md:w-3/4 lg:w-2/3 md:max-w-[1280px] md:px-4">
            <div
              // className="flex flex-row items-center space-x-1 cursor-pointer md:flex-col md:flex-row"
              onClick={() => {
                ga.event({
                  action: 'go-to-homepage',
                  category: 'navigate-in-menue',
                  label: '--',
                });
                if (
                  router.asPath ===
                    `${BASE_URLS.default}${query && `?${query}`}` ||
                  router.asPath === `${BASE_URLS.A1}${query && `?${query}`}`
                ) {
                  router.reload();
                } else {
                  const baseUrl =
                    window.sessionStorage.getItem('campaign') === 'a1click'
                      ? BASE_URLS.A1
                      : BASE_URLS.default;
                  router.push(`${baseUrl}${query && `?${query}`}`);
                }
              }}
            >
              <img
                src="/static/images/logos/mem/memoresaShop.svg"
                alt="memoresa logo"
                width={isSm ? 147 : 104}
                height={isSm ? 36 : 26}
                className="md:min-w-[147px] min-w-[104px] h-[26px] md:h-[36px] cursor-pointer "
              />
            </div>
            <div
              className="items-center justify-around hidden space-x-4 md:flex [&>#lang]:ml-8 [&>#lang]:mr-2"
              id="navbar-container"
              onBlur={e => {
                if (
                  e?.relatedTarget?.id === 'category-product' ||
                  e?.relatedTarget?.id === 'lang-item'
                )
                  return;
                setSelectedCategory(undefined);
              }}
            >
              {navigationEntries?.map((category, i) => (
                <div key={category.id} className="cursor-pointer">
                  <div
                    className="flex items-center hover:border-b-2 hover:border-primary focus-visible:ring-2 focus-visible:ring-primary focus-visible:outline-none hover:-mb-[2px]"
                    id={`category-${category.id}`}
                    tabIndex={0}
                    onClick={() =>
                      setSelectedCategory(selectedCat =>
                        selectedCat !== category.id ? category.id : undefined
                      )
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setSelectedCategory(selectedCat =>
                          selectedCat !== category.id ? category.id : undefined
                        );
                      }
                    }}
                  >
                    <div className="relative text-lg font-medium text-primary whitespace-nowrap">
                      {category.name}
                    </div>
                    <Icon
                      path={
                        selectedCategory === category.id
                          ? mdiChevronUp
                          : mdiChevronDown
                      }
                      size={0.8}
                      className="text-primary"
                    />
                  </div>
                  {selectedCategory === category.id && (
                    <div
                      className="absolute flex flex-col w-full p-1 space-y-2 font-medium bg-primary-contrast shadow-paper-shadow text-primary "
                      style={{
                        minWidth: document.getElementById(
                          `category-${category.id}`
                        )?.offsetWidth,
                        maxWidth: 'max-content',
                      }}
                    >
                      {category.items.map(item => (
                        <div
                          onClick={() => {
                            if (
                              router.asPath ===
                              `/shop/${item.key}${query && `?${query}`}`
                            ) {
                              router.reload();
                            } else {
                              router.push(
                                `/shop/${item.key}${query && `?${query}`}`
                              );
                            }
                          }}
                          className="w-full"
                          key={item.id}
                        >
                          <div
                            className="hover:bg-primary-5 focus-visible:bg-primary-5 p-0.5 outline-none w-full"
                            tabIndex={0}
                            id={`category-product`}
                            onClick={() => {
                              setSelectedCategory(undefined);
                            }}
                          >
                            <Typography>{item.name}</Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              <div
                className="cursor-pointer flex items-center space-x-2 hover:border-b-2 hover:border-primary focus-visible:ring-2 focus-visible:ring-primary focus-visible:outline-none hover:-mb-[2px]"
                id={`faq`}
                tabIndex={0}
                onClick={() => router.push('/shop/faq')}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    router.push('/shop/faq');
                  }
                }}
              >
                <div className="relative text-lg font-medium text-primary">
                  FAQs
                </div>
              </div>
              <div className="cursor-pointer" id={`lang`}>
                <div
                  className="cursor-pointer flex items-center hover:border-b-2 hover:border-primary focus-visible:ring-2 focus-visible:ring-primary focus-visible:outline-none hover:-mb-[2px]"
                  tabIndex={0}
                  onClick={() =>
                    setSelectedCategory(selectedCat =>
                      selectedCat !== 'lang' ? 'lang' : undefined
                    )
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setSelectedCategory(selectedCat =>
                        selectedCat !== 'lang' ? 'lang' : undefined
                      );
                    }
                  }}
                >
                  <div className="text-lg font-medium text-primary">
                    {router.locale?.toUpperCase()}
                  </div>
                  <Icon
                    path={
                      selectedCategory === 'lang'
                        ? mdiChevronUp
                        : mdiChevronDown
                    }
                    size={0.8}
                    className="text-primary"
                  />
                </div>
                {selectedCategory === 'lang' && (
                  <div
                    className="absolute flex flex-col w-full p-1 space-y-2 font-medium bg-primary-contrast shadow-paper-shadow text-primary "
                    style={{
                      minWidth: document.getElementById(`lang`)?.offsetWidth,
                      maxWidth: 'max-content',
                    }}
                  >
                    {router?.locales?.map(item => (
                      <div
                        onClick={() => {
                          router.push(router.asPath, undefined, {
                            locale: item,
                          });
                          i18n.changeLanguage(item);
                        }}
                        className="w-full"
                        key={item}
                      >
                        <div
                          className="hover:bg-primary-5 focus-visible:bg-primary-5 p-0.5 outline-none w-full"
                          tabIndex={0}
                          id={`lang-item`}
                          onClick={() => {
                            setSelectedCategory(undefined);
                          }}
                        >
                          <Typography className="px-2">
                            {item.toUpperCase()}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <CartButton />
            </div>
          </div>
          <div className="md:hidden">
            <CartButton />
          </div>
        </div>
      </div>
    </div>
  );
};
