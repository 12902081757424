import { Typography } from '@memoresa/ui-components';
import parse from 'html-react-parser';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
export const Footer = () => {
  const { t } = useTranslation();
  const getUrl = (
    path:
      | 'shipping'
      | 'payment'
      | 'returns'
      | 'conditions'
      | 'revocation'
      | 'privacy'
      | 'imprint'
  ) => {
    switch (path) {
      case 'shipping':
        return `/agb/#versand`;
      case 'payment':
        return `/agb/#zahlungsarten`;
      case 'returns':
        return `/widerruf`;
      case 'conditions':
        return `/agb`;
      case 'revocation':
        return `/widerruf`;
      case 'privacy':
        return `/datenschutz`;
      case 'imprint':
        return `/impressum`;
      default:
        let def: never = path;
        return def;
    }
  };
  return (
    <div className="flex flex-col items-center w-full bg-[#F7F7F7] mt-6 ">
      {/* Top footer */}
      <div className="max-w-[1280px] md:w-3/4 lg:w-2/3 grid grid-cols-4 w-full py-4 text-primary gap-4  px-4">
        <div className="flex flex-col items-center space-y-4 sm:items-start col-span-full sm:col-span-1">
          <Typography className="font-medium uppercase">
            {t('navigation.footer.paymentMethods')}
          </Typography>
          <div className="flex flex-wrap justify-center w-3/4 gap-4 sm:justify-start ">
            <img
              src="/static/images/topFooter/Paypal_Icon.png"
              alt="paypal"
              className="object-contain w-[107px] h-[27px]"
            />
            <img
              src="/static/images/topFooter/giropay_Icon.png"
              alt="giropay"
              className="object-contain h-[44px] w-[120px]"
            />
            <img
              src="/static/images/topFooter/Sepa_Icon.png"
              alt="sepa"
              className="object-contain w-[108px] h-[44px] min-w-[56px]"
            />
            <div className="flex flex-wrap gap-4 sm:flex-nowrap sm:flex-row">
              <img
                src="/static/images/topFooter/Mastercard_Icon.svg"
                alt="Mastercard"
                className="object-contain w-[44px] h-[33px]"
              />
              <img
                src="/static/images/topFooter/Visa_Icon.svg"
                alt="visa"
                className="object-contain w-[80px] h-[33px]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 sm:items-start col-span-full sm:col-span-1">
          <Typography className="font-medium uppercase">
            {t('navigation.footer.shippingPartners')}
          </Typography>
          <div className="flex flex-wrap justify-center w-3/4 gap-4 sm:justify-start ">
            <img
              src="/static/images/topFooter/DHL_Icon.png"
              alt="dhl"
              className="object-contain min-w-[100px] w-[124px] h-[27px]"
            />
            <img
              src="/static/images/topFooter/LVZ_Icon.jpg"
              alt="lvz"
              className="object-contain w-[128px] h-[27px]"
            />
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 sm:items-start col-span-full sm:col-span-1">
          <Typography className="font-medium uppercase">
            {t('navigation.footer.safety')}
          </Typography>
          <div className="flex flex-wrap justify-center w-3/4 gap-4 sm:justify-start ">
            <a
              href="www.allianz-fuer-cybersucherheit.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/static/images/topFooter/Allianz_Icon.jpg"
                alt="Allianz"
                className="object-contain w-[113px] h-[45px]"
              />
            </a>
            <img
              src="/static/images/topFooter/Partner_Icon.png"
              alt="partner"
              className="object-contain w-[45px] h-[45px]"
            />
            <img
              src="/static/images/topFooter/SecurityTrust_Icon.png"
              alt="security trust"
              className="object-contain w-[45px] h-[45px]"
            />
            <img
              src="/static/images/topFooter/DSGVO_Icon.png"
              alt="dsgvo"
              className="object-contain w-[80px] h-[30px]"
            />
            <img
              src="/static/images/topFooter/Germany_Icon.png"
              alt="made in germany"
              className="object-contain w-[45px] h-[45px]"
            />
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 sm:items-start col-span-full sm:col-span-1">
          <Typography className="font-medium uppercase">
            {t('navigation.footer.customerOpinion')}
          </Typography>
          <div className="flex flex-wrap justify-center w-3/4 gap-4 sm:justify-start ">
            <img
              src="/static/images/topFooter/Customer_Icon.svg"
              alt="e trust customer"
              className="object-contain w-[75px] h-[57px]"
            />
            <img
              src="/static/images/topFooter/PopularShop_Icon.svg"
              alt="popular shop"
              className="object-contain w-[42px] h-[75px]"
            />
          </div>
        </div>
      </div>
      {/* Bottom footer */}
      <div className="flex flex-col items-center justify-center w-full py-4 space-y-6 bg-primary text-primary-contrast ">
        <div className="flex space-x-8 ">
          <a
            href="https://www.instagram.com/dienotfallkarte.de/ "
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-contrast"
          >
            <div className="w-8 h-8 bg-transparent rounded-full">
              <img
                src="/static/images/social/Instagram.svg"
                className="object-contain"
                alt="Instagram"
              />
            </div>
          </a>
          <a
            href="https://www.facebook.com/meingesundheitspassde-105044392130946/"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-contrast"
          >
            <div className="w-8 h-8 bg-transparent rounded-full">
              <img
                src="/static/images/social/Facebook.svg"
                className="object-contain"
                alt="Facebook"
              />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/memoresa/"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-contrast"
          >
            <div className="w-8 h-8 bg-transparent rounded-full">
              <img
                src="/static/images/social/Linkedin.svg"
                className="object-contain"
                alt="linkedin"
              />
            </div>
          </a>

          <a
            href="https://www.tiktok.com/@meingesundheitspass.de"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-contrast"
          >
            <div className="w-8 h-8 bg-transparent rounded-full">
              <img
                src="/static/images/social/TikTok.svg"
                className="object-contain"
                alt="TikTok"
              />
            </div>
          </a>
        </div>
        <div className="px-4 max-w-[1280px] md:w-3/4 lg:w-2/3 grid w-full grid-cols-3 gap-8 text-center uppercase sm:text-left sm:gap-0">
          <div className="flex flex-col items-center sm:items-start col-span-full sm:col-span-1 ">
            <div className="flex flex-col justify-around space-y-2 w-max md:space-y-0">
              <a
                href="https://emergency.memoresa.de/mem/start"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={0}
                className="group focus:outline-none"
              >
                <Typography className="footer-text">
                  {t('navigation.footer.myAccount')}
                </Typography>
              </a>
              <Link href={getUrl('shipping')} tabIndex={0}>
                <a
                  className="group focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className="footer-text">
                    {t('navigation.footer.shipping')}
                  </Typography>
                </a>
              </Link>
              <Link href={getUrl('payment')} tabIndex={0}>
                <a
                  className="group focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className="footer-text">
                    {t('navigation.footer.paymentMethods')}
                  </Typography>
                </a>
              </Link>
              <Link href={getUrl('returns')} tabIndex={0}>
                <a
                  className="group focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className="footer-text">
                    {t('navigation.footer.returnShipments')}
                  </Typography>
                </a>
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-center col-span-full sm:col-span-1">
            <div className="flex flex-col justify-around space-y-2 w-max md:space-y-0">
              <a
                href="https://dienotfallkarte.de/kontakt/"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={0}
                className="group focus:outline-none"
              >
                <Typography className="footer-text">
                  {t('navigation.footer.contact')}
                </Typography>
              </a>
              <a
                href="https://dienotfallkarte.de/apotheke/"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={0}
                className="group focus:outline-none"
              >
                <Typography className="footer-text">
                  {t('navigation.footer.pharmacy')}
                </Typography>
              </a>
              <a
                href="https://dienotfallkarte.de/memoresa-partner/"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={0}
                className="group focus:outline-none"
              >
                <Typography className="footer-text">
                  {t('navigation.footer.becomePartner')}
                </Typography>
              </a>
              <a
                href="https://ec-order.memoresa.de/mem/register?m_src=shop"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={0}
                className="group focus:outline-none"
              >
                <Typography className="footer-text">
                  {t('navigation.footer.partnerPortal')}
                </Typography>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center sm:items-end col-span-full sm:col-span-1">
            <div className="flex flex-col justify-around space-y-2 w-max md:space-y-0">
              <Link href={getUrl('conditions')} tabIndex={0}>
                <a
                  className="group focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className="footer-text">
                    {t('navigation.footer.agb')}
                  </Typography>
                </a>
              </Link>
              <Link href={getUrl('revocation')} tabIndex={0}>
                <a
                  className="group focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className="footer-text">
                    {t('navigation.footer.revocation')}
                  </Typography>
                </a>
              </Link>
              <Link href={getUrl('privacy')} tabIndex={0}>
                <a
                  className="group focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className="footer-text">
                    {t('navigation.footer.dataProtection')}
                  </Typography>
                </a>
              </Link>
              <Link href={getUrl('imprint')} tabIndex={0}>
                <a
                  className="group focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className="footer-text">
                    {t('navigation.footer.imprint')}
                  </Typography>
                </a>
              </Link>
            </div>
          </div>
        </div>

        <div className="w-10/12 text-center md:w-8/12">
          <Typography className="text-[14px]">
            {parse(
              t('navigation.footer.designedBy', {
                year: new Date().getFullYear(),
              })
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};
