import clsx from 'clsx';
import { ReactNode } from 'react';
import { AlertType } from 'react-alert';

interface AlertProps {
  iconMapping: Record<string, ReactNode>;
  style?: Record<string, string | number>;
  severity: AlertType;
  action?: ReactNode;
}

const Alert: React.FC<AlertProps> = ({
  iconMapping,
  style,
  severity,
  action,
  children,
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center space-x-5 px-5 items-center w-auto h-fit py-1 rounded',
        severity === 'success' && 'bg-success',
        severity === 'error' && 'bg-error',
        severity === 'info' && 'bg-primary'
      )}
      style={{ zIndex: 2000, ...style }}
    >
      <div>{iconMapping[severity]}</div>
      <div className="flex flex-grow">{children}</div>
      <div>{action && action}</div>
    </div>
  );
};

export default Alert;
