import { useEffect, useState } from 'react';

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
    isXs?: boolean;
    isXxs?: boolean;
    isSm?: boolean;
    isMd?: boolean;
    isLg?: boolean;
    isXl?: boolean;
    isXXl?: boolean;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
    isXs: window.innerWidth > 0,
    isXxs: window.innerWidth <= 450,
    isSm: window.innerWidth >= 600,
    isMd: window.innerWidth >= 960,
    isLg: window.innerWidth >= 1280,
    isXl: window.innerWidth >= 1920,
    isXXl: window.innerWidth >= 2560,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const isXs = window.innerWidth > 0;
      const isXxs = window.innerWidth <= 450;
      const isSm = window.innerWidth >= 600;
      const isMd = window.innerWidth >= 960;
      const isLg = window.innerWidth >= 1280;
      const isXl = window.innerWidth >= 1920;
      const isXXl = window.innerWidth >= 2560;
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isXs,
        isXxs,
        isSm,
        isMd,
        isLg,
        isXl,
        isXXl,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return { ...windowSize };
};

export default useWindowSize;
