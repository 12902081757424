import { useEffect } from 'react';
import { AlertType, useAlert } from 'react-alert';
import qs from 'query-string';
import { useRouter } from 'next/router';

export const APIMessagesConsumer = () => {
  const router = useRouter();
  const alert = useAlert();

  useEffect(() => {
    const { search, hash, pathname } = location;

    if (location.search) {
      const parsedSearch = qs.parse(search);
      const { message, messageType } = parsedSearch;

      const formattedMessageType =
        (Array.isArray(messageType)
          ? messageType.map(item => item?.toLowerCase())[0]
          : messageType?.toLowerCase()) ?? 'info';

      if (message && formattedMessageType) {
        alert.show(message, {
          type: formattedMessageType as unknown as AlertType,
          timeout: 5000,
        });
        delete parsedSearch.message;
        delete parsedSearch.messageType;
        router.push({
          pathname,
          search: qs.stringify(parsedSearch),
          hash,
        });
      }
    }
  }, [location.search]);

  return null;
};
