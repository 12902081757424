import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import serviceWorkerEventListener from '../../../services/ServiceWorkerEventListener';

export const ServiceWorkerAlertConsumer: React.FC = () => {
  const { show } = useAlert();
  const { t } = useTranslation();

  const getTimeout = type => {
    switch (type) {
      case 'success': {
        return 2000;
      }
      case 'error': {
        return 4000;
      }
      case 'info': {
        return 2000;
      }
      default:
        return 0;
    }
  };

  const getAlertTranslation = useCallback(
    id => {
      switch (id) {
        case 'serviceWorker.alert.noconnection':
          return t('serviceWorker.alert.noconnection');
        default:
          return '';
      }
    },
    [t]
  );

  const showAlert = useCallback(
    ({
      data: {
        payload: { type, id },
      },
    }) => {
      show(getAlertTranslation(id), { type, timeout: getTimeout(type) });
    },
    [getAlertTranslation, show]
  );

  useEffect(() => {
    serviceWorkerEventListener.subscribe('alert', showAlert);
    return () => serviceWorkerEventListener.unsubscribe('alert', showAlert);
  }, [showAlert]);

  return null;
};
