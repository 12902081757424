import { Typography } from '@memoresa/ui-components';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { useCartContext } from '../../context/cart/useCartContext';
import * as ga from '../../lib/ganalytics';
import ShoppingCartIcon from '../../resources/icons/shopping-cart.svg';
export const CartButton: React.FC<{
  variant?: 'primary' | 'contrast';
}> = ({ variant = 'primary' }) => {
  const {
    state: { products },
  } = useCartContext();
  const router = useRouter();

  const handleClick = () => {
    ga.event({
      action: 'go-to-cart',
      category: 'navigate-in-menue',
      label: '--',
    });
    router.pathname !== '/shop/cart' && router.push('/shop/cart');
  };

  return (
    <div
      className={clsx(
        router.pathname !== '/shop/cart'
          ? 'cursor-pointer hover:bg-primary-5 group active:bg-primary focus-visible:ring-2 focus-visible:ring-primary '
          : 'cursor-default',
        ' my-2 w-[36px] aspect-square rounded-full flex items-center justify-center relative outline-none'
      )}
      tabIndex={router.pathname !== '/shop/cart' ? 0 : -1}
      role="button"
      onClick={handleClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
    >
      <ShoppingCartIcon
        className={clsx(
          'group-active:fill-primary-contrast  w-[24px] h-[24px]',
          variant === 'primary' && 'fill-primary',
          variant === 'contrast' && 'fill-primary-contrast'
        )}
      />
      {products && products.length > 0 && (
        <div
          data-cy="shopping-cart-amount"
          className="absolute flex items-center justify-center w-5 h-5 rounded-full -top-1 -right-1 bg-secondary text-secondary-contrast"
        >
          <Typography className="text-sm">{products.length}</Typography>
        </div>
      )}
    </div>
  );
};
