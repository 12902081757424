import { mdiCheck, mdiClose, mdiExclamation, mdiInformation } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Button, Typography } from '@memoresa/ui-components';
import React from 'react';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import Alert from '../Alert';

type Props = {
  style: any;
  options: any;
  message: any;
  close: any;
};

export const AlertTemplate: React.FC<Props> = ({
  style,
  options,
  message,
  close,
}) => {
  const { isSm } = useWindowSize();

  return (
    <Alert
      iconMapping={{
        success: <Icon path={mdiCheck} size={1} color={'#fff'} />,
        error: <Icon path={mdiExclamation} size={1} color={'#fff'} />,
        info: <Icon path={mdiInformation} size={1} color={'#fff'} />,
      }}
      severity={options.type}
      action={
        <Button
          iconButton={mdiClose}
          size="small"
          onClick={() => close()}
          variant="text"
          type="button"
          style={{ background: 'transparent' }}
          iconButtonIconClassName="text-white"
        />
      }
      style={{ marginRight: 12, maxWidth: !isSm ? 320 : 'none', ...style }}
    >
      <Typography style={{ color: '#fff', fontSize: '14px' }}>
        {message}
      </Typography>
    </Alert>
  );
};
