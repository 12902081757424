import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { IVoucher } from '../../services/cart/CartService';
import { calcPriceParsed } from '../../utils/functions/calcPriceParsed';
import { VolumeDiscountSetting } from '../../utils/interfaces/Product';
export type CartProduct = {
  id: string;
  image: string;
  name: string;
  amount: number;
  maxAmount: number;
  minAmount: number;
  amountStepping: number;
  basePrice: number;
  totalPrice: string;
  volumeDiscountSettings: VolumeDiscountSetting[];
  details?: { name: string; value: string; id: string }[];
  stackable: boolean;
  internalId: string;
};
export const cartContextInitialState = {
  voucher: undefined,
  products:
    (typeof window !== 'undefined' &&
      JSON.parse(localStorage.getItem('memShopCart') || '[]')) ??
    [],
};

export type CartContextState = {
  voucher?: IVoucher;
  products: CartProduct[];
};

export interface CartContext {
  state: CartContextState;
  dispatch: React.Dispatch<ReducerAction>;
}

type ReducerAction =
  | {
      type: 'REMOVE_PRODUCT';
      payload: string;
    }
  | {
      type: 'ADD_PRODUCT';
      payload: Omit<CartProduct, 'internalId' | 'totalPrice'>;
    }
  | { type: 'EDIT_PRODUCT'; payload: CartProduct }
  | { type: 'SET_VOUCHER'; payload: IVoucher }
  | { type: 'RESET_PRODUCTS' }
  | { type: 'RESET_VOUCHER' };

const mainReducer = (state: CartContextState, action: ReducerAction) => {
  switch (action.type) {
    case 'SET_VOUCHER': {
      return { ...state, voucher: action.payload };
    }
    case 'RESET_VOUCHER': {
      return { ...state, voucher: undefined };
    }
    case 'ADD_PRODUCT': {
      let updatedProducts: CartProduct[];
      let error: 'maxAmount' | null = null;
      const payload = {
        ...action.payload,
        internalId: v4(),
        totalPrice: calcPriceParsed(
          action.payload.basePrice,
          action.payload.amount,
          action.payload.volumeDiscountSettings
        ),
      };
      // If products cannot be stacked, just push them to the array
      if (!payload.stackable) {
        updatedProducts = !!state.products.length
          ? [...state.products, payload]
          : [payload];
      } else {
        // If can be stacked, but its the first on, push it to the array
        if (!state.products || state.products.length === 0) {
          updatedProducts = [payload];
          //If can be stacked and it isn't the first one, just add 1 to the amount of the product
        } else {
          const itemIndex = state.products.findIndex(
            obj => obj.name === payload.name
          );
          //This controls that new items cannot be added if the amount is limited
          if (itemIndex !== -1) {
            const productsWithUpdatedAmount = [...state.products];
            const limitAmount = productsWithUpdatedAmount[itemIndex].maxAmount;
            const newAmount =
              productsWithUpdatedAmount[itemIndex].amount +
              productsWithUpdatedAmount[itemIndex].amountStepping;
            if (newAmount < limitAmount) {
              productsWithUpdatedAmount[itemIndex].amount =
                productsWithUpdatedAmount[itemIndex].amount +
                productsWithUpdatedAmount[itemIndex].amountStepping;
              //Also update the prices
              productsWithUpdatedAmount[itemIndex].totalPrice = calcPriceParsed(
                productsWithUpdatedAmount[itemIndex].basePrice,
                productsWithUpdatedAmount[itemIndex].amount,
                productsWithUpdatedAmount[itemIndex].volumeDiscountSettings
              );
            } else {
              // error = 'maxAmount';
            }
            updatedProducts = productsWithUpdatedAmount;
          } else {
            updatedProducts = [...state.products, payload];
          }
        }
      }
      localStorage.setItem('memShopCart', JSON.stringify(updatedProducts));
      return {
        ...state,
        products: updatedProducts,
        error,
      };
    }
    case 'REMOVE_PRODUCT': {
      const products = state.products;
      const productsSplice = products.filter(
        product => product.internalId !== action.payload
      );
      localStorage.setItem('memShopCart', JSON.stringify(productsSplice));
      return {
        ...state,
        products: productsSplice,
      };
    }
    case 'EDIT_PRODUCT': {
      const products = [...state.products];
      const selectedProductIndex = state.products.findIndex(
        product => product.internalId === action.payload.internalId
      );
      products[selectedProductIndex] = action.payload;
      (products[selectedProductIndex].totalPrice = calcPriceParsed(
        action.payload.basePrice,
        action.payload.amount,
        action.payload.volumeDiscountSettings
      )),
        localStorage.setItem('memShopCart', JSON.stringify(products));
      return {
        ...state,
        products: products,
      };
    }
    case 'RESET_PRODUCTS': {
      localStorage.removeItem('memShopCart');
      localStorage.removeItem('directBuy');
      return { products: [] };
    }

    default:
      return state;
  }
};

export const CartContext = createContext<CartContext | undefined>(undefined);

type CartContextProviderProps = {
  initialValue?: CartContextState;
};

const CartContextProvider: React.FC<CartContextProviderProps> = ({
  initialValue,
  children,
}) => {
  const [state, dispatch] = useReducer(
    mainReducer,
    initialValue ?? cartContextInitialState
  );

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error(
      `useCartContext must be used within the CartContextProvider`
    );
  }
  const { state, dispatch } = context;
  const alert = useAlert();
  const { t } = useTranslation();
  const setVoucher = (voucher: IVoucher) => {
    dispatch({ type: 'SET_VOUCHER', payload: voucher });
  };
  const resetVoucher = () => {
    dispatch({ type: 'RESET_VOUCHER' });
  };
  const addProduct = (
    product: Omit<CartProduct, 'internalId' | 'totalPrice'>
  ) => {
    dispatch({ type: 'ADD_PRODUCT', payload: product });
    const existingProduct = state.products.find(
      sProduct => sProduct.id === product.id
    );
    const currentAmount = existingProduct?.amount ?? 0;
    if (
      product.stackable &&
      existingProduct &&
      product.amount >= 2 - currentAmount
    ) {
      alert.error(
        t('cartPage.itemMaxAmountReached', {
          value: `${product.name}`,
        }),
        {
          timeout: 3000,
        }
      );
    }
  };
  const removeProduct = (internalId: string) => {
    dispatch({ type: 'REMOVE_PRODUCT', payload: internalId });
  };
  const editProduct = (product: CartProduct) => {
    dispatch({ type: 'EDIT_PRODUCT', payload: product });
  };
  const resetProducts = () => {
    dispatch({ type: 'RESET_PRODUCTS' });
  };
  return {
    state,
    addProduct,
    removeProduct,
    editProduct,
    // getTotalPrice,
    setVoucher,
    resetVoucher,
    resetProducts,
  };
};

export { CartContextProvider, useCartContext };
