import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@memoresa/ui-components';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Dispatch, useEffect, useState } from 'react';
import i18n from '../../i18n';
import { NavigationEntry } from '../../utils/interfaces/NavigationEntry';
const categoryVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
const wrapperVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: '-200%',
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
export const MenuPopover: React.FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  categories?: NavigationEntry[];
}> = ({ isOpen, setIsOpen, categories }) => {
  const router = useRouter();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath]); //eslint-disable-line
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <div className="relative z-[10] duration-500 transition-all">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      {isOpen && (
        <div
          className="fixed top-[54px] left-0 w-screen h-screen z-[10] bg-black/30"
          aria-hidden="true"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      )}
      <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'}>
        <motion.div
          variants={itemVariants}
          key="items"
          className="fixed top-[54px] left-0 flex items-center justify-center w-full px-4 bg-white z-[101] border-t border-t-primary-10"
        >
          <motion.ul
            variants={wrapperVariants}
            className="flex flex-col items-center w-full py-4 pl-2 space-y-4 text-primary"
          >
            {categories?.map(category => (
              <motion.li
                variants={categoryVariants}
                key={category.id}
                className="flex flex-col justify-center space-y-2 text-center"
                onClick={() =>
                  setSelectedCategory(openCategory =>
                    openCategory === category.id ? undefined : category.id
                  )
                }
              >
                <div className="flex items-center justify-center space-x-2">
                  <Typography className="text-[16px] font-bold">
                    {category.name}
                  </Typography>
                  <motion.div
                    className="h-max"
                    initial={{ rotate: 0 }}
                    animate={
                      selectedCategory === category.id
                        ? { rotate: 180 }
                        : { rotate: 0 }
                    }
                    exit={{ rotate: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.2 }}
                  >
                    <Icon path={mdiChevronDown} size={0.8} />
                  </motion.div>
                </div>
                {selectedCategory === category.id && (
                  <div className="flex flex-col space-y-4 text-center">
                    {category.items.map(item => (
                      <div
                        onClick={() => {
                          router.asPath;
                          if (router.asPath === `/shop/${item.key}`) {
                            router.reload();
                          } else {
                            router.push(`/shop/${item.key}`);
                          }
                        }}
                        key={item.id}
                      >
                        <Typography className="text-[16px] font-medium">
                          {item.name}
                        </Typography>
                      </div>
                    ))}
                  </div>
                )}
              </motion.li>
            ))}
            <motion.li
              variants={categoryVariants}
              id={`faq`}
              tabIndex={0}
              onClick={() => router.push('/shop/faq')}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  router.push('/shop/faq');
                }
              }}
              className="!list-none"
            >
              <div className="text-[16px] font-bold">FAQs</div>
            </motion.li>
            <motion.li
              variants={categoryVariants}
              id={`lang`}
              tabIndex={0}
              className="!list-none flex space-x-1 pt-3 justify-between "
            >
              {router.locales?.map(item => (
                <div
                  key={item}
                  onClick={() => {
                    router.push(router.asPath, undefined, {
                      locale: item,
                    });
                    i18n.changeLanguage(item);
                    setIsOpen(false);
                  }}
                  className={clsx(
                    item === router.locale
                      ? 'font-bold underline'
                      : 'font-regular',
                    "px-2 relative text-[16px] flex-divider:block flex-divider:absolute flex-divider:left-[37.2px] flex-divider:top-1/2 flex-divider:-translate-y-[40%] flex-divider:content-[''] flex-divider:w-[1.5px] flex-divider:h-[15px] flex-divider:bg-primary"
                  )}
                >
                  {item.toUpperCase()}
                </div>
              ))}
            </motion.li>
          </motion.ul>
        </motion.div>
      </motion.nav>
    </div>
  );
};
