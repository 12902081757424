import * as React from 'react';
import { useReducer } from 'react';

export type Dialog = {
  component: React.ReactElement;
};

export type DialogContextState = {
  dialogStack: Dialog[];
};

type ReducerAction =
  | {
      type: 'PUSH_DIALOG';
      dialog: React.ReactElement;
    }
  | {
      type: 'CLEAR_AND_PUSH_DIALOG';
      dialog: React.ReactElement;
    }
  | { type: 'POP_DIALOG' }
  | { type: 'CLEAR_STACK' };

const DialogsContext = React.createContext<DialogContextState | undefined>(
  undefined
);
const DialogsContextReducer = React.createContext<
  React.Dispatch<ReducerAction> | undefined
>(undefined);

const dialogReducer = (
  state: DialogContextState,
  action: ReducerAction
): DialogContextState => {
  switch (action.type) {
    case 'PUSH_DIALOG': {
      return {
        ...state,
        dialogStack: [
          ...state.dialogStack,
          {
            component: action.dialog,
          },
        ],
      };
    }
    case 'CLEAR_AND_PUSH_DIALOG': {
      return {
        dialogStack: [
          {
            component: action.dialog,
          },
        ],
      };
    }
    case 'POP_DIALOG': {
      state.dialogStack.pop();
      return {
        ...state,
        dialogStack: state.dialogStack,
      };
    }
    case 'CLEAR_STACK': {
      return {
        ...state,
        dialogStack: [],
      };
    }
  }
};

const debugLog = (s: string) =>
  process.env.NODE_ENV === 'development' && console.log(s);

const DialogProvider: React.FC<{
  initialState?: DialogContextState;
}> = props => {
  const [state, dispatch] = useReducer(
    dialogReducer,
    props.initialState ?? {
      dialogStack: [],
    }
  );

  return (
    <DialogsContext.Provider value={state}>
      <DialogsContextReducer.Provider value={dispatch}>
        {props.children}
      </DialogsContextReducer.Provider>
    </DialogsContext.Provider>
  );
};

const useDialogContextState = () => {
  const state = React.useContext(DialogsContext);
  if (!state) {
    throw new Error(`useDialogContext must be used within a DialogProvider`);
  }
  return state;
};

const useDialogContext = () => {
  const dispatch = React.useContext(DialogsContextReducer);
  if (!dispatch) {
    throw new Error(`useDialogContext must be used within a DialogProvider`);
  }

  const pushDialog = (dialog: React.ReactElement) => {
    debugLog('PUSH_DIALOG');
    dispatch({
      type: 'PUSH_DIALOG',
      dialog,
    });
  };

  const popDialog = () => {
    debugLog('POP_DIALOG');
    dispatch({
      type: 'POP_DIALOG',
    });
  };

  const clearDialogStack = () => {
    debugLog('CLEAR_STACK');

    dispatch({
      type: 'CLEAR_STACK',
    });
  };

  /**
   * clears the stack and sets a dialog
   * @param dialog
   */
  const clearStackAndPush = (dialog: React.ReactElement) => {
    debugLog('CLEAR_AND_PUSH_DIALOG');
    clearDialogStack();
    pushDialog(dialog);
    // dispatch({
    //   type: 'CLEAR_AND_PUSH_DIALOG',
    //   dialog,
    // });
  };

  return {
    pushDialog,
    popDialog,
    clearDialogStack,
    clearStackAndPush,
  };
};

export { DialogProvider, useDialogContext, useDialogContextState };
