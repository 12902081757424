import merge from 'deepmerge';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import tinycolor from 'tinycolor2';
// import {
//   baseDarkTheme,
//   baseLightTheme,
//   baseTheme,
// } from './themes/themes/base.theme';
import Values from 'values.js';
// import { ThemePalette } from './themes/themes/theme';
import {
  baseDarkTheme,
  baseLightTheme,
  baseTheme,
  ThemePalette,
} from '@memoresa/themes';

interface IThemeContext {
  theme: Partial<ThemePalette>;
}

type ThemeContextType =
  | {
      state: IThemeContext;
      setState: React.Dispatch<React.SetStateAction<IThemeContext>>;
    }
  | undefined;

const ThemeContext = React.createContext<ThemeContextType>(undefined);

export type IThemeProvider = {
  themeObject?: Partial<ThemePalette>;
};

export const ThemeProvider: React.FC<IThemeProvider> = ({
  children,
  themeObject = {},
}) => {
  const [state, setState] = useState({
    theme: themeObject,
  });

  useEffect(() => {
    setState({
      theme: palette(),
    });
  }, []);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );

  /**
   * Memoize the merged theme
   */
  const palette = useCallback(() => {
    const mergedBaseTheme =
      themeObject.type === 'dark'
        ? merge(baseTheme, baseDarkTheme)
        : (merge(baseTheme, baseLightTheme) as any);

    // HERE MUST MERGE IF EXIST THE themeOverride PROP

    return merge(mergedBaseTheme, themeObject);
  }, [themeObject]);

  /**
   * Modify DOM styles
   */
  const d = document.documentElement;
  const bodyStyles = d.style;

  /**
   * first 10 colors fade from colors[0] = white -> colors[10] = base
   * last 10 colors fade from colors[10] = base -> colors[20] = black
   */
  const generateColorPallete = (colorIdentifier: string, baseColor: string) =>
    new Values(baseColor).all(10).forEach((color: any) => {
      // map the shade in a range of 0 - 100
      const valueModifier =
        color.type === 'tint' ? 50 - color.weight / 2 : 50 + color.weight / 2;

      bodyStyles.setProperty(
        `${colorIdentifier}-${valueModifier}`,
        `#${color.hex}`
      );
    });

  generateColorPallete('--color-primary', palette().primary.main);
  generateColorPallete('--color-secondary', palette().secondary.main);
  generateColorPallete('--color-error', palette().error.main);
  generateColorPallete('--color-success', palette().success.main);
  generateColorPallete('--color-warning', palette().warning.main);

  /**
   * OPACITY PALETE
   */
  const generateOpacityPallete = (
    colorIdentifier: string,
    baseColor: string
  ) => {
    for (let index = 0; index <= 100; index += 5) {
      bodyStyles.setProperty(
        `${colorIdentifier}-${index}`,
        tinycolor(baseColor)
          .setAlpha(index / 100)
          .toString('hex8')
      );
    }
  };
  generateOpacityPallete('--color-primary-opacity', palette().primary.main);
  generateOpacityPallete('--color-secondary-opacity', palette().secondary.main);
  generateOpacityPallete('--color-error-opacity', palette().error.main);
  generateOpacityPallete('--color-success-opacity', palette().success.main);
  generateOpacityPallete('--color-warning-opacity', palette().warning.main);

  /**
   * BACKGROUND
   */
  const bgColors = [
    ['--color-background', palette().background.main],
    [
      '--color-background-contrast',
      tinycolor
        .mostReadable(
          palette().background.main,
          [palette().background.contrast as tinycolor.ColorInput],
          {
            level: 'AA',
          }
        )
        .toString('hex8'),
    ],
  ];

  /**
   * PRIMARY
   */
  const primaryColors = [
    ['--color-primary', palette().primary.main],
    [
      '--color-primary-contrast',
      palette().primary.main ??
        tinycolor.mostReadable(
          palette().primary.contrast as tinycolor.ColorInput,
          ['white', 'black']
        ),
    ],
    [
      '--color-primary-light',
      tinycolor(palette().primary.main).lighten(5).toString('hex8'),
    ],
    [
      '--color-primary-light-10',
      tinycolor(palette().primary.main).lighten(10).toString('hex8'),
    ],
    [
      '--color-primary-dark',
      tinycolor(palette().primary.main).darken(5).toString('hex8'),
    ],
    [
      '--color-primary-contrast',
      palette().primary.contrast ??
        tinycolor
          .mostReadable(palette().primary.main, ['white', 'black'], {
            includeFallbackColors: true,
          })
          .toString('hex8'),
    ],
    [
      '--color-primary-bright-10',
      tinycolor(palette().primary.main).brighten(10).toString('hex8'),
    ],
    [
      '--color-primary-bright-20',
      tinycolor(palette().primary.main).brighten(20).toString('hex8'),
    ],
    [
      '--color-primary-bright-30',
      tinycolor(palette().primary.main).brighten(30).toString('hex8'),
    ],
    [
      '--color-primary-opacity-15',
      tinycolor(palette().primary.main).setAlpha(0.15).toString('hex8'),
    ],
  ];

  /**
   * SECONDARY
   */
  const secondaryColors = [
    ['--color-secondary', palette().secondary.main],
    [
      '--color-secondary-light',
      tinycolor(palette().secondary.main).lighten(5).toString('hex8'),
    ],
    [
      '--color-secondary-dark',
      tinycolor(palette().secondary.main).darken(5).toString('hex8'),
    ],
    [
      '--color-secondary-contrast',
      palette().secondary.contrast ??
        tinycolor
          .mostReadable(palette().secondary.main, ['white', 'black'], {
            includeFallbackColors: true,
          })
          .toString('hex8'),
    ],
    [
      '--color-secondary-opacity-25',
      tinycolor(palette().secondary.main).setAlpha(0.25).toString('hex8'),
    ],
  ];

  /**
   * PAPER
   */
  const paperColors = [
    ['--color-paper', palette().paper.main],
    [
      '--color-paper-10',
      tinycolor(palette().paper.main).setAlpha(0.1).toString('hex8'),
    ],
    [
      '--color-paper-20',
      tinycolor(palette().paper.main).setAlpha(0.2).toString('hex8'),
    ],
    [
      '--color-paper-contrast',
      palette().paper.contrast ??
        tinycolor
          .mostReadable(palette().paper.main, ['white', 'black'], {
            includeFallbackColors: true,
          })
          .toString('hex8'),
    ],
  ];

  /**
   * TEXT
   */
  const textColors = [
    ['--color-text-primary', palette().text.primary],
    ['--color-text-secondary', palette().text.secondary],
    ['--color-text-disabled', palette().text.disabled],
  ];

  /**
   * INFO, SUCCESS, ERROR, WARNING
   */
  const miscColors = [
    ['--color-info', palette().info.main],
    [
      '--color-info-contrast',
      tinycolor
        .mostReadable(
          palette().info.main,
          [palette().info.contrast as tinycolor.ColorInput],
          {
            includeFallbackColors: true,
          }
        )
        .toString('hex8'),
    ],

    ['--color-success', palette().success.main],
    [
      '--color-success-light',
      tinycolor(palette().success.main).lighten(10).toString('hex8'),
    ],
    [
      '--color-success-dark',
      tinycolor(palette().success.main).darken(10).toString('hex8'),
    ],
    [
      '--color-success-contrast',
      tinycolor
        .mostReadable(
          palette().success.main,
          [palette().success.contrast as tinycolor.ColorInput],
          {
            includeFallbackColors: true,
          }
        )
        .toString('hex8'),
    ],

    ['--color-error', palette().error.main],
    [
      '--color-error-light',
      tinycolor(palette().error.main).lighten(10).toString('hex8'),
    ],
    [
      '--color-error-dark',
      tinycolor(palette().error.main).darken(10).toString('hex8'),
    ],
    [
      '--color-error-contrast',
      tinycolor
        .mostReadable(
          palette().error.main,
          [palette().error.contrast as tinycolor.ColorInput],
          {
            includeFallbackColors: true,
          }
        )
        .toString('hex8'),
    ],

    ['--color-warning', palette().warning.main],
    [
      '--color-warning-light',
      tinycolor(palette().warning.main).lighten(10).toString('hex8'),
    ],
    [
      '--color-warning-dark',
      tinycolor(palette().warning.main).darken(10).toString('hex8'),
    ],
    [
      '--color-warning-contrast',
      tinycolor
        .mostReadable(
          palette().warning.main,
          [palette().warning.contrast as tinycolor.ColorInput],
          {
            includeFallbackColors: true,
          }
        )
        .toString('hex8'),
    ],
  ];

  [
    ...bgColors,
    ...primaryColors,
    ...secondaryColors,
    ...paperColors,
    ...textColors,
    ...miscColors,
  ].forEach(color => bodyStyles.setProperty(color[0], color[1]));

  /**
   * NAVBAR
   */
  bodyStyles.setProperty('--color-navbar-bg', palette().navbar.bg);
  bodyStyles.setProperty('--color-navbar-color', palette().navbar.color);
  bodyStyles.setProperty('--color-navbar-hover', palette().navbar.hover);

  /**
   * DISABLED
   */
  bodyStyles.setProperty('--color-disabled', palette().disabled);

  /**
   * FONT
   */
  bodyStyles.setProperty('font-family', palette().fontFamily);

  /**
   * LIST ITEM
   */
  bodyStyles.setProperty('--color-hover-listItem', palette().hover.listItem);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = (): { state: IThemeContext } => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error(`useThemeContext must be used within a ThemeContext`);
  }
  const { state } = context;

  return {
    state,
  };
};
