import { hasCookie, setCookie } from 'cookies-next';
import { useLayoutEffect } from 'react';
import { useDialogContext } from '../../context/dialogs/DialogsContext';
import { CookieDialog } from './CookieDialog';
export const CookieDetector = () => {
  const acceptCookie = () => {
    setCookie('cookiePreferences', 'all', { maxAge: 60 * 60 * 24 * 365 });
    // @ts-ignore
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  };

  const denyCookie = () => {
    setCookie('cookiePreferences', 'functional', {
      maxAge: 60 * 60 * 24 * 365,
    });
  };
  const { pushDialog } = useDialogContext();
  useLayoutEffect(() => {
    if (!hasCookie('cookiePreferences')) {
      pushDialog(<CookieDialog onAccept={acceptCookie} onDeny={denyCookie} />);
    }
  }, []); //eslint-disable-line
  return null;
};
