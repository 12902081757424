// log the pageview with their URL
export const pageview = url => {
  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

// log specific events happening.
export const event = ({ action, category, label }) => {
  window.gtag(
    'event',
    `${action}${process.env.NODE_ENV === 'development' && '-dev'}`,
    {
      event_category: `${category}${
        process.env.NODE_ENV === 'development' && '-dev'
      }`,
      event_label: `${label}${
        process.env.NODE_ENV === 'development' && '-dev'
      }`,
    }
  );
};
