import Axios, { AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import i18n from '../../i18n';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Timezone: new Date().getTimezoneOffset().toString(),
  TimezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const request = <T = any>(config: AxiosRequestConfig & { lang?: string }) => {
  const modConfig = config;
  modConfig.withCredentials = config.withCredentials ?? true;
  modConfig.headers = {
    'Accept-Language': config.lang ?? i18n.language,
    ...defaultHeaders,
    ...config.headers,
  };
  const query = qs.extract(config.url as string);
  const locale = typeof window !== 'undefined' && localStorage.getItem('lang');
  //Set the locale for every request, we still have to add the header manually to every request in the `api` folder
  modConfig.url = `${config.url}${
    query.length === 0 ? `?locale=${locale}` : `&locale=${locale}`
  }`;
  return Axios(modConfig);
};

export const RequestManagerNext = {
  get: <T = any>(
    url: string,
    config?: AxiosRequestConfig & { lang?: string }
  ) => {
    return request<T>({
      method: 'get',
      url,
      ...config,
    });
  },
  post: <T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    config?: AxiosRequestConfig
  ) =>
    request<T>({
      method: 'post',
      url,
      data: data,
      ...config,
    }),

  put: <T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    config?: AxiosRequestConfig
  ) =>
    request<T>({
      method: 'put',
      url,
      data,
      ...config,
    }),

  patch: <T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    config?: AxiosRequestConfig
  ) =>
    request<T>({
      method: 'patch',
      url,
      ...config,
    }),

  delete: <T = any>(url: string, config?: AxiosRequestConfig) =>
    request<T>({
      method: 'delete',
      url,
      ...config,
    }),
};
