import RenderIfVisible from 'react-render-if-visible';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

const AppLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="flex justify-center w-full min-h-[calc(100vh-46px)] flex-grow md:mt-[121px] mt-[54px]">
        {/* Here I set the max width of the main content, we can change it if it's too big/small */}
        <div className="w-full lg:px-4 md:px-0 md:w-3/4 lg:w-2/3 max-w-[1280px]">
          {children}
        </div>
      </div>
      <RenderIfVisible defaultHeight={400} visibleOffset={-20}>
        <Footer />
      </RenderIfVisible>
    </>
  );
};
export default AppLayout;
