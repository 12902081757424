import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './public/static/locale/de/common.json';
import en from './public/static/locale/en/common.json';

export const supportedLanguages = ['de', 'en'];
export const fallbackLanguage = 'de';

export const resources = {
  en: {
    common: en,
  },
  de: {
    common: de,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: supportedLanguages,
    nonExplicitSupportedLngs: true,
    fallbackLng: code => {
      if (!code) {
        return 'de';
      }
      const fallbacks: string[] = [];
      if (code.includes('-')) {
        if (supportedLanguages.includes(code.split('-')[0]))
          fallbacks.push(code.split('-')[0]);
      }
      return fallbacks;
    },
    resources,
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupLocalStorage: 'lang',
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span'],
    },
    defaultNS: 'common',
  });

export default i18n;
