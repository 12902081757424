import { Button, Typography } from '@memoresa/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialogContext } from '../../context/dialogs/DialogsContext';
import { memoresaLogoB64 } from '../../utils/consts/memoresaLogoB64';
import { noInternetImageB64 } from '../../utils/consts/noInternetImageB64';
import useWindowSize from '../../utils/hooks/useWindowSize';
import parse from 'html-react-parser';

export const InternetConnectionChecker = ({ children }) => {
  // const { setIsOnline } = useMainContext();
  const { clearDialogStack } = useDialogContext();
  const { isSm } = useWindowSize();
  const { t } = useTranslation();
  const [isOnline, setLocalOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setLocalOnline(navigator.onLine);
    // setIsOnline(navigator.onLine);
    if (typeof window !== undefined) {
      window.addEventListener('online', () => {
        setLocalOnline(true);
        // setIsOnline(true);
      });
      window.addEventListener('offline', () => {
        clearDialogStack();
        setLocalOnline(false);
        // setIsOnline(false);
      });
    }
    return () => {
      window.removeEventListener('online', () => {
        setLocalOnline(true);
        // setIsOnline(true);
      });
      window.removeEventListener('offline', () => {
        clearDialogStack();
        setLocalOnline(false);
        // setIsOnline(false);
      });
    };
  }, []);
  const reloadPage = () => {
    typeof window !== 'undefined' && window.location.reload();
  };

  if (isOnline) {
    return children;
  }
  return (
    <div className="flex flex-col items-center justify-start w-full px-4 pt-6 pb-10">
      <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
        <img
          src={`data:image/png;base64,${memoresaLogoB64}`}
          alt="memoresa logo"
          style={{
            width: isSm ? 260 : 190,
          }}
        />
        <img
          src={`data:image/svg+xml;base64,${noInternetImageB64}`}
          alt="You are offline"
          style={{
            width: isSm ? 260 : 190,
            height: isSm ? 260 : 190,
          }}
        />

        <Typography
          color="primary"
          className="text-[33px] md:text-[48px] font-medium text-center"
        >
          {t('noInternet.title')}
        </Typography>

        <Typography
          color={'primary'}
          className={
            'text-sm sm:text-lg font-light flex flex-col items-center justify-center'
          }
        >
          {parse(t('noInternet.description'))}
        </Typography>
        <Button
          onClick={reloadPage}
          style={{
            textTransform: 'uppercase',
          }}
        >
          {t('noInternet.reload')}
        </Button>
      </div>
    </div>
  );
};
