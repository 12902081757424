class ServiceWorkerEventListener {
  private instance;
  private worker;
  private channel;
  private handlers;

  constructor() {
    // if (typeof ServiceWorkerEventListener.instance === 'object') {
    //   return ServiceWorkerEventListener.instance;
    // }
    this.worker = null;
    this.channel = null;
    this.handlers = {};

    // ServiceWorkerEventListener.instance = this;
  }

  initialize(worker) {
    this.worker = worker;
    this.channel = new MessageChannel();
    worker.postMessage(
      {
        type: 'INIT_PORT',
      },
      [this.channel.port2]
    );

    this.channel.port1.onmessage = event => this.fire(event);
  }

  /**
   * @typedef {object} Event
   */
  /**
   * @typedef {object} CustomEventData
   * @property {string} type
   * @property {object} payload
   */

  /**
   * @typedef {object} CustomEvent
   * @augments Event
   * @property {CustomEventData} data
   * */
  /**
   * @param {CustomEventData} data
   */
  /**
   * @param {CustomEvent} event
   * @description Executes all handlers for event
   */
  fire(event) {
    const {
      data: { type },
    } = event;
    if (this.handlers[type])
      this.handlers[type].forEach(handler => handler(event));
  }

  /**
   * A handle function to run when a event occurs
   * @callback EventHandler
   * @params {Event} e
   */
  /**
   * @param {string} eventType Event identifier
   * @param {EventHandler} handler Function to run when the event type occurs
   */
  subscribe(eventType, handler: any) {
    if (this.handlers[eventType])
      this.handlers[eventType] = [...this.handlers[eventType], handler];
    else this.handlers[eventType] = [handler];
  }

  /**
   * @param {string} eventType Event identifier
   * @param {EventHandler} fn Function to unsubscribe from the handlers
   */
  unsubscribe(eventType, fn) {
    this.handlers[eventType] = this.handlers[eventType].filter(
      handler => handler !== fn
    );
  }
}

const instance = new ServiceWorkerEventListener();

export default instance;
