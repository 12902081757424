import { VolumeDiscountSetting } from '../interfaces/Product';

export const calcPriceParsed = (
  priceInCents: number,
  amount: number,
  volumeDiscountSettings?: VolumeDiscountSetting[]
) => {
  const initialPriceParsed = priceInCents / 100;
  const price = initialPriceParsed * amount;
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
  if (volumeDiscountSettings) {
    //Get the setting with the closest amount
    const correctSetting = volumeDiscountSettings.reduce((prev, curr) =>
      Math.abs(curr.minQuantity - amount) < Math.abs(prev.minQuantity - amount)
        ? curr
        : prev
    );
    if (amount >= correctSetting.minQuantity) {
      const calcPrice =
        price - (price * correctSetting.discountPercentage) / 100;
      return formatter.format(calcPrice);
    }
  }
  return formatter.format(price);
};
