import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
interface IMetaContent {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

//TODO: For the images we need an URL, so no Base64 images.
export const MetaContent: React.FC<IMetaContent> = ({
  title,
  description,
  image,
  url,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
        charSet="utf-8"
      />
      <link rel="shortcut icon" href="/static/images/favicon_blue.ico" />
      <title>{title ?? t('memoresa')}</title>

      {title && description && url && (
        <>
          <meta name="title" content={title} lang={locale} />
          <meta name="description" content={description} lang={locale} />
          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_SHOP_URL}/shop/${url}`}
          />
          <meta property="og:title" content={title} lang={locale} />
          <meta property="og:description" content={description} lang={locale} />
          <meta
            property="og:image"
            content={`${process.env.NEXT_PUBLIC_BASE_SHOP_URL}${image}`}
          />
          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.NEXT_PUBLIC_BASE_SHOP_URL}/shop/${url}`}
          />
          <meta property="twitter:title" content={title} lang={locale} />
          <meta
            property="twitter:description"
            content={description}
            lang={locale}
          />
          <meta
            property="twitter:image"
            content={`${process.env.NEXT_PUBLIC_BASE_SHOP_URL}${image}`}
          />
        </>
      )}
    </Head>
  );
};
