import React, { useEffect, useState } from 'react';
import {
  useDialogContext,
  useDialogContextState,
} from '../../context/dialogs/DialogsContext';

type BaseDialogProps = {
  open: boolean;
  onClose: (...args: any) => void;
  onBack: () => void;
  onExitCrossClick: () => void;
};

export type DialogConsumerProps<K = unknown> = {
  dialogConsumerProps?: BaseDialogProps;
  /**
   * This is here to support legacy dialogs whilst migrating.
   * We need to figure out how we are going to handle callbacks
   */
} & Partial<BaseDialogProps> &
  K;

/**
 * Gets the dialog component from the state and creates a copy
 * of it to be displayed on the page.
 */
const DialogConsumer: React.FC = () => {
  const [lastPath, setLastPath] = useState<string | null>(null);

  const { dialogStack } = useDialogContextState();
  const { clearDialogStack, popDialog } = useDialogContext();

  /**
   * we want to close the dialog if the user
   * navigates to a different page in the app
   */
  useEffect(() => {
    if (!lastPath) setLastPath(location.pathname);
    if (lastPath && lastPath !== location.pathname) {
      if (!dialogStack) return;
      setLastPath(null);
      clearDialogStack();
    }
  }, [location]); //eslint-disable-line

  /**
   * The dialog array is reversed so that the latest
   * one is rendered on top of all the other portals
   */

  return (
    <>
      {dialogStack.map((theDialog, i) => {
        const dialogProps = {
          // spread the dialog-specific props
          ...theDialog.component.props,

          // do the dialog-consumer magic here :)
          key: i,
          open: i === dialogStack.length - 1, // only open the last one

          onClose: () => {
            /**
             * executes the callback defined when doing
             * pushDialog(<SomeDialog onClose={callback} />)
             *
             * When overriding the onClose prop inside a dialog,
             * we *MUST* call the dialogConsumerProps.onClose()
             * to delegate the closing of the dialog to this Consumer
             */
            if (theDialog.component.props.onClose)
              theDialog.component.props.onClose();

            clearDialogStack();
          },
          // Dialogs from the context clear the stack when clicking the cross icon */
          onExitCrossClick: () => {
            clearDialogStack();
          },

          // this goes to the previous dialogs
          onBack: () => {
            // Call the user-override of onClose before closing
            if (theDialog.component.props.onClose)
              theDialog.component.props.onClose();
            popDialog();
          },
        };
        return React.cloneElement(
          theDialog.component,
          {
            dialogConsumerProps: dialogProps,
            // we now pass them all inside dialogConsumerProps to simplify the props
            ...dialogProps,
            // stop deleting here
          },
          theDialog.component.props.children
        );
      })}
    </>
  );
};

export default DialogConsumer;
