import { Button, MemDialog, Typography } from '@memoresa/ui-components';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import * as ga from '../../lib/ganalytics';
import { DialogConsumerProps } from '../dialogs/DialogConsumer';
import DialogWrapper from '../dialogs/DialogWrapper';

export const CookieDialog: React.FC<DialogConsumerProps<any>> = ({
  dialogConsumerProps,
  onAccept,
  onDeny,
}) => {
  const { t } = useTranslation();
  const handleSaveChanges = (value: 'all' | 'functional') => {
    if (value === 'all') {
      onAccept();
    } else {
      onDeny();
    }
    ga.event({
      action:
        value === 'all' ? 'accept-all-cookies' : 'only-functional-cookies',
      category: 'cookie-banner',
      label:
        value === 'all' ? 'accept-all-button' : 'functional-cookies-button',
    });
    dialogConsumerProps?.onClose();
  };

  return (
    <DialogWrapper
      {...dialogConsumerProps}
      title={t('dialogs.cookies.title')}
      canBeClosed={false}
    >
      <MemDialog.Content mainImage={'/static/images/utils/cookie-monster.svg'}>
        <Typography color="primary" className="text-[16px]">
          {parse(t('dialogs.cookies.description'))}
        </Typography>
      </MemDialog.Content>
      <div className="flex flex-col items-center justify-center mt-8 space-y-4">
        <div className="w-full sm:w-[60%]">
          <Button
            fullWidth
            data-cy="functional-cookies-button"
            onClick={() => handleSaveChanges('functional')}
            style={{
              textTransform: 'uppercase',
            }}
          >
            {t('dialogs.cookies.acceptFunctional')}
          </Button>
        </div>
        <div className="w-full sm:w-[60%]">
          <Button
            fullWidth
            data-cy="all-cookies-button"
            onClick={() => handleSaveChanges('all')}
            style={{
              textTransform: 'uppercase',
            }}
          >
            {t('dialogs.cookies.acceptAll')}
          </Button>
        </div>
      </div>
    </DialogWrapper>
  );
};
