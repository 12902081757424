import React from 'react';
import { positions, Provider as ReactAlertProvider } from 'react-alert';
import { AlertTemplate } from './template/AlertTemplate';
import { ServiceWorkerAlertConsumer } from './consumers/ServiceWorkerAlertConsumer';
import { APIMessagesConsumer } from './consumers/APIMessagesConsumer';

export const AlertProvider: React.FC = ({ children }) => {
  return (
    <ReactAlertProvider
      template={AlertTemplate}
      position={positions.TOP_RIGHT}
      containerStyle={{
        marginTop: 100,
        zIndex: 9999,
      }}
    >
      {children}
      <ServiceWorkerAlertConsumer />
      <APIMessagesConsumer />
    </ReactAlertProvider>
  );
};
