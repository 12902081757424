import { NavigationEntry } from '../../utils/interfaces/NavigationEntry';
import { RequestManagerNext } from '../requestManager/requestManagerNext';

export const CategoriesService = {
  getNavigationEntries: async ({
    campaign,
  }: {
    campaign?: string;
  }): Promise<NavigationEntry[]> => {
    const { data } = await RequestManagerNext.get(
      `/api/categories/getNavigationEntries${
        campaign && campaign !== 'undefined' ? `?campaign=${campaign}` : ''
      }`
    );
    return data;
  },
};

export default CategoriesService;
