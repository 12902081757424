import { MemDialog } from '@memoresa/ui-components';
import { DialogBaseProps } from '@memoresa/ui-components/dist/DialogBase.props';
import * as React from 'react';

export type DialogWrapperProps = DialogBaseProps & { onBack?: () => void };

const DialogWrapper: React.FC<DialogWrapperProps> = React.forwardRef(
  ({ ...props }, ref) => {
    //@ts-ignore
    return (
      <MemDialog
        {...props}
        initialFocus={props.initialFocus}
        /**
         * sometimes this is null and fails
         */
        open={props.open}
        /**
         * If a dialog is being used as vanilla, se can set this sensible default
         * usually vanilla dialogs wont launch more dialogs
         * so we can just got back to empty the dialog stack
         */
        onExitCrossClick={props.onExitCrossClick ?? props.onClose}
        ref={ref}
      />
    );
  }
);

DialogWrapper.displayName = 'DialogWrapper';

export default DialogWrapper;
